<template>
    <div>
        <h2 class="profile">Mailbox List</h2>
        <div class="incomeList">
            <el-form label-width="68px" :inline="true">
                <el-form-item label="userName" prop="userName">
                    <el-input placeholder="" clearable />
                </el-form-item>
                <el-form-item label="nickName" prop="nickName">
                    <el-input placeholder="" clearable />
                </el-form-item>
                <!-- <el-form-item label="状态" prop="status">
                      <el-select
                        v-model="queryParams.status"
                        placeholder="状态"
                        clearable
                      >
                        <el-option
                          v-for="dict in radioList"
                          :key="dict.value"
                          :label="dict.label"
                          :value="dict.value"
                        ></el-option>
                      </el-select>
                    </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" size="small">search</el-button>
                    <el-button size="small">reset</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="messageData" style="width: 85%">
                <el-table-column prop="username" label="username" width="160" />
                <el-table-column prop="nikename" label="nikename" width="160" />
                <el-table-column prop="createtime" label="createtime" width="160" />
                <el-table-column label="state" align="center" prop="status" width="100">
                    <template #default="scope">
                        <el-tag v-if="scope.row.status == 0">user</el-tag>
                        <el-tag v-else-if="scope.row.status == 1">adviser</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="operate">
                    <template #default="scope">
                        <el-button size="small" type="primary"
                            @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script setup>
import { reactive, ref, onMounted } from 'vue'
const messageData = reactive([
    {
        username: 'wss',
        nikename: 'yyy',
        createtime: '2016-05-07',
        status: '0'
    }
])
const handleDelete = (index, row) => {

}
</script>

<style lang="scss" scoped>
.incomeList {
    padding: 30px 20px;
}

.profile {
    line-height: 50px;
    border-bottom: 1px solid #c2bcbc;
    padding-left: 20px;
    color: #02758a;
}
</style>
